'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Button, PageLayout, Section, Text } from '@sal-solution/ui-web';
import { FC } from 'react';
import * as Icon from '@heroicons/react/24/outline';
import Link from 'next/link';

export const ConfirmationPage: FC = () => {
  return (
    <PageLayout>
      <Section tw="px-8 flex-col items-center justify-center">
        <Icon.CheckCircleIcon tw="w-24 h-24 text-green-500 " />
        <Text tw="text-center" variant="title">
          Uw bestelling is ontvangen!
        </Text>
        <Text tw="text-center">
          We hebben de bestelling ontvangen en zullen zo snel mogelijk een
          status update delen.
        </Text>
        <Button as={Link} href="/" tw="mt-4">
          Terug naar de voor pagina
        </Button>
      </Section>
    </PageLayout>
  );
};
