'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  PageLayout,
  Section,
  SmartForm,
  SmartFormElement,
  Text,
} from '@sal-solution/ui-web';
import React from 'react';
import { useRouter } from 'next/navigation';
import { useCreateOrderMutation } from './graphql/createOrder.generated';
import { OrderInput } from '@sal-solution/types/types/fish-shop/types';

export const OrderPage = () => {
  const routes = useRouter();
  const [createOrder, { loading, error }] = useCreateOrderMutation();
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);

    const order: OrderInput = {
      customer: {
        name: data.get('name') as string,
        email: data.get('email') as string,
        phone: data.get('phone') as string,
        street: data.get('street') as string,
        houseNumber: data.get('houseNumber') as string,
        postalCode: data.get('postalCode') as string,
        city: data.get('city') as string,
      },
      products: [
        {
          _id: '667db23c6f694fc74f4f11e1',
          quantity: data.get('quantity') as string,
        },
      ],
      comment: data.get('comment') as string,
      shopId: '667db0fd04e58896db2e40b7',
    };

    const response = await createOrder({
      variables: {
        order,
      },
    });
    if (response.data?.createOrder?.shopId) routes.push('/bevestiging');
    else console.log('error');
  };

  return (
    <PageLayout>
      <Section tw="px-4 flex flex-col">
        {loading && <Text>Loading</Text>}
        {error?.message && <Text tw="text-red-500">{error.message}</Text>}
        <SmartForm
          tw="w-full h-full max-w-screen-md mx-auto"
          onSubmit={onSubmit}
          elements={[userElements, addressElements]}
        />
      </Section>
    </PageLayout>
  );
};

const orderElements: SmartFormElement[] = [
  {
    type: 'radio',
    name: 'quantity',
    label: 'Hoe veel KG wilt u bestellen?',
    radios: [
      {
        name: '0.5kg',
        label: '0.5 KG',
        value: '0.5',
      },
      {
        name: '1kg',
        label: '1 KG',
        value: '1',
      },
      {
        name: '5kg',
        label: '2 KG',
        value: '2',
      },
      {
        name: '10kg',
        label: '10 KG',
        value: '10',
      },
      {
        name: '15kg',
        label: '15 KG',
        value: '15',
      },
      {
        name: '20kg',
        label: '20 KG',
        value: '20',
      },
    ],
  },
];

const userElements: SmartFormElement[] = [
  {
    type: 'input',
    name: 'name',
    label: 'Naam',
    placeholder: 'Uw naam',
    required: true,
  },
  {
    type: 'input',
    name: 'email',
    label: 'Email',
    placeholder: 'Uw email',
    required: true,
  },
  {
    type: 'input',
    name: 'phone',
    label: 'Telefoonnummer',
    placeholder: 'Uw telefoonnummer',
    required: true,
  },
  {
    type: 'input',
    name: 'quantity',
    label: 'Hoe veel KG wilt u bestellen?',
    placeholder: 'Uw gewicht',
    required: true,
  },
  {
    type: 'textArea',
    name: 'comment',
    label: 'Bericht',
    placeholder: 'Uw bericht',
  },
];

const addressElements: SmartFormElement[] = [
  {
    type: 'input',
    name: 'street',
    label: 'Straat',
    placeholder: 'Uw straat',
    required: true,
  },
  {
    type: 'input',
    name: 'houseNumber',
    label: 'Huisnummer',
    placeholder: 'Uw huisnummer',
    required: true,
  },
  {
    type: 'input',
    name: 'postalCode',
    label: 'Postcode',
    placeholder: 'Uw postcode',
    required: true,
  },
  {
    type: 'input',
    name: 'city',
    label: 'Plaats',
    placeholder: 'Uw plaats',
    required: true,
  },
];
