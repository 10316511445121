'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  PageLayout,
  Product,
  Section,
  StatusBar,
  Text,
} from '@sal-solution/ui-web';
import { ComponentProps, FC } from 'react';
import WALogo from './assets/WALogo.png';
import image from './assets/productImage2.jpeg';
import { useRouter } from 'next/navigation';
import { images } from './images';
import { useFindProductQuery } from './graphql/findProduct.generated';
import { useFindShopQuery } from './graphql/findShop.generated';
type statusTypes = NonNullable<ComponentProps<typeof StatusBar>['status']>;

const statusTexts: Record<statusTypes, string> = {
  success: 'Wij zijn nu op zee, U kunt bestellen.',
  warning: 'Bestellen is straks niet meer mogelijk. Bestel nu!',
  error: 'Wij zijn niet op zee, U kunt niet bestellen.',
};

export const HomePage: FC = () => {
  const foundProduct = useFindProductQuery({
    variables: {
      id: '667db23c6f694fc74f4f11e1',
    },
  });

  const foundShop = useFindShopQuery({
    variables: {
      shopId: '667db0fd04e58896db2e40b7',
    },
  });
  const router = useRouter();
  const onClick = () => {
    if (foundShop.data?.shop?.status === 'error') return;

    router.push('/bestellen');
  };
  const shop = foundShop.data?.shop;
  const product = foundProduct.data?.product;
  const isLoading = foundProduct.loading || foundShop.loading;
  const isError = foundProduct.error || foundShop.error;
  return (
    <PageLayout>
      <Section tw="py-0 lg:py-0 2xl:py-0">
        {isLoading && <Text>Loading...</Text>}
        {isError && <Text tw="text-red-500">{isError.message}</Text>}
        <div tw="fixed top-4 w-full max-w-screen-md z-20 px-4 flex flex-col space-y-4">
          <StatusBar
            tw="w-full"
            status={(shop?.status as statusTypes) || 'success'}
          >
            {statusTexts[(shop?.status as statusTypes) || 'success']}
          </StatusBar>
          <StatusBar status="warning" tw="w-full hidden lg:flex">
            Onze website is momenteel voor mobile bedoelt. Op desktop kunt u een
            minder fijne ervaring beleven en raden we daarom aan om over te
            schakelen naar mobiel
          </StatusBar>
        </div>
        <Product
          onClick={onClick}
          name={product?.name || 'Verse Garnalen 🦐'}
          features={product?.features as string[]}
          image={image.src}
          images={images}
          WALogo={WALogo.src}
        />
      </Section>
    </PageLayout>
  );
};
