import * as Types from '@sal-solution/types/types/fish-shop/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type FindProductQuery = { __typename?: 'Query', product?: { __typename?: 'Product', _id: string, name: string, price?: number | null, quantity?: string | null, description?: string | null, features?: Array<string | null> | null, amount?: number | null, image?: string | null, images?: Array<string | null> | null, shopId: string, created_at?: string | null, updated_at?: string | null } | null };


export const FindProductDocument = gql`
    query findProduct($id: ID!) {
  product(_id: $id) {
    _id
    name
    price
    quantity
    description
    features
    amount
    image
    images
    shopId
    created_at
    updated_at
  }
}
    `;

/**
 * __useFindProductQuery__
 *
 * To run a query within a React component, call `useFindProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindProductQuery(baseOptions: Apollo.QueryHookOptions<FindProductQuery, FindProductQueryVariables> & ({ variables: FindProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, options);
      }
export function useFindProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductQuery, FindProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, options);
        }
export function useFindProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindProductQuery, FindProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, options);
        }
export type FindProductQueryHookResult = ReturnType<typeof useFindProductQuery>;
export type FindProductLazyQueryHookResult = ReturnType<typeof useFindProductLazyQuery>;
export type FindProductSuspenseQueryHookResult = ReturnType<typeof useFindProductSuspenseQuery>;
export type FindProductQueryResult = Apollo.QueryResult<FindProductQuery, FindProductQueryVariables>;